<template>
  <div class="col-12 col-lg-6 c g">
    <div class="card">
        <div class="card-header">
            <h5>
                اعدادات الواتس الذكي
            </h5>
        </div>
        <div class="card-body">
            <div class="form-group">
              <span for="">JWT</span>
              <input type="text"
                class="form-control form-control-lg" v-model="jwt">
            </div>
            <div class="form-group">
              <span for="">رقم الجهاز</span>
              <input type="text"
                class="form-control form-control-lg" v-model="device_id">
            </div>
            <hr>
            <div class="form-group">
              <label for="">ايقاف توزيع الخيام</label>
              <select class="form-control" v-model="stop_arrange_camps">
                <option value="no">ايقاف</option>
                <option value="yes">تشغيل</option>
              </select>
            </div>
            <div class="form-group">
              <label for="">ايقاف توزيع الحافلات</label>
              <select class="form-control" v-model="stop_arrange_busses">
                <option value="no">ايقاف</option>
                <option value="yes">تشغيل</option>
              </select>
            </div>
        </div>
        <div class="card-footer text-center">
            <button class="btn btn-primary" @click="save()">
                <i class="fa fa-save"></i>
                حفظ الاعدادات
            </button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            user: JSON.parse(localStorage.getItem("user")),
            device_id: '',
            jwt: '',
            stop_arrange_camps: '',
            stop_arrange_busses: ''
        }
    },
    created(){
        if(!checkPer('*')){
            this.$router.push('/pers')
            return;
        }
        var g = this;
        $.post(api + '/admin/web/settings', {
            jwt: g.user.jwt
        }).then(function(r){
            g.jwt = r.response.jwt;
            g.device_id = r.response.device_id;
            g.stop_arrange_camps = r.response.stop_arrange_camps;
            g.stop_arrange_busses = r.response.stop_arrange_busses;
        })
    },
    methods: {
        save(){
            var g = this;
            $.post(api + '/admin/web/settings/save', {
                jwt: g.user.jwt,
                device_id: g.device_id,
                stop_arrange_camps: g.stop_arrange_camps,
                stop_arrange_busses: g.stop_arrange_busses,
                jwtv: g.jwt
            }).then(function(r){
               location.reload()
            })
        }
    }
}
</script>

<style>

</style>